import React, { useState } from "react";
import Input from "../../Input";

const ConditionAccordion = ({
  additionalSelect,
  id,
  nodeId,
  inputChangeHandler,
}) => {
  const [open, setOpen] = useState(false);
  const [subInputs, setSubInputs] = useState([]);
  
  return (
    <div className="additionalSelectHolder" key={additionalSelect.id}>
      <div
        onClick={() => setOpen((e) => !e)}
        className={`additionalSelectTitle ${
          open ? "additionalSelectOpen" : "additionalSelectClose"
        }`}
      >
        {additionalSelect?.key}
      </div>
      <div
        style={{ display: open ? "block" :"none", padding: open ? "0.4rem" : 0 }}
        className="additionalSelectInputs"
      >
        {additionalSelect?.inputs.length > 0 &&
          additionalSelect?.inputs.map((input) => (
            <div key={input?.id + input?.key}>
            <Input
              key={input?.id + input?.key}
              id={id}
              nodeId={nodeId}
              registerId={`params.${id}.params.${input?.id}`}
              input={input}
              inputChangeHandler={
                input?.type === "select" && inputChangeHandler
              }
              setSubInputs={
                input?.type === "select" && setSubInputs
              }
              type={input?.type}
            />
               {input?.type === "select" &&
                            subInputs.find(
                              (e) => e.id === `params.${id}.params.${input?.id}`
                            ) &&
                            subInputs
                              .find(
                                (e) =>
                                  e.id === `params.${id}.params.${input?.id}`
                              )
                              ?.subInputs?.sort((a, b) =>
                                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                              )
                              ?.map((subInput, index) => (
                                <Input
                                  key={subInput.id + index}
                                  value={subInput?.value}
                                  id={id}
                                  input={subInput}
                                  registerId={`params.${id}.params.${subInput?.id}`}
                                  type={subInput?.type}
                                  nodeId={nodeId}
                                />
                              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ConditionAccordion;
