export const projectSettingsDefaults = {
  magic_and_other: {
    magic_number: Math.floor(1000 + Math.random() * 9000),
    expiration_date: "",
    on_timer_period: 60,
  },
  pip_size: {
    rules: "0.001 = 0.01,0.00001 = 0.0001,0.000001 = 0.0001",
  },
  description_and_version_number: {
    copy_right: "",
    description: "",
    website_address: "",
    version_number: "",
  },
  virtual_stops: {
    virtual_stops: false,
    virtual_stops_time_out: 0,
    emergency_stops: "no",
    relative_size: 0,
    add_pips: 0,
  },
  visual: {
    display_spread_meter: true,
    display_status_messages: true,
    display_indicators_after_test: true,
  },
};
